'use client'

import { useState } from 'react';
import InputField from "./inputField"
import SubTitle from "./subtitle"
import SelectField from "./select"
import { sendGAEvent } from '@next/third-parties/google';

const options = [
  { value: 'Pix', label: 'Pix' },
  { value: 'Cartão de Crédito', label: 'Cartão de Crédito' },
  { value: 'Cartão de Débito', label: 'Cartão de Débito' },
  { value: 'Dinheiro', label: 'Dinheiro' },
  { value: 'Outro', label: 'Outro' }
];

const rentingOptions = [
  { value: '3 dias (caçamba externa)', label: '3 dias (caçamba externa)' },
  { value: '5 dias (caçamba interna)', label: '5 dias (caçamba interna)' },
  { value: 'Outro', label: 'Outro' }
];

export default function OrderForm() {

  const [name, setName] = useState('não informado');
  const [address, setAddress] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [timeRent, setTimeRent] = useState('Não inserido');
  const [payment, setPayment] = useState('Não inserido');
  const [quantity, setQuantity] = useState('');
  const splittedDate = deliveryDate.split('-');

  const handleSubmit = (e) => {
    e.preventDefault();
    const title = `Olá, venho do site www.mraremocao.com.br e gostaria de um orçamento com as seguintes informações:\n\n`
    const body = `*Nome:* ${name}\n*Endereço:* ${address}\n*Quantidade de caçambas:* ${quantity}\n*Para o dia:* ${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}\n*Pelo prazo de:* ${timeRent}\n*Forma de pagamento*: ${payment}`
    const whatsappNumber = '5511992048864';
    const message = `${title}${body}`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <form 
      className="bg-pure-white rounded-md grid grid-cols-2 border light-gray-border md:grid-cols-12 max-[1140px]:text-base min-[1140px]:text-xl"
      target="_blank"
      onSubmit={handleSubmit}
      id='OrderForm'
      >
      <div className="col-span-2 mt-7 mb-7 ml-7 md:col-span-12">
        <SubTitle text={"Faça já seu pedido"}/>
      </div>
      <div className="col-span-2 ml-7 mr-7 md:col-span-4">
      <InputField 
        title={"Qual é o seu nome?"} 
        placeholder={"insira seu nome completo"} 
        type={"text"} 
        identifier={"name"}
        setter={setName}
      />
      </div>
      <div className="col-span-2 ml-7 mr-7 md:col-span-4">
      <InputField 
        title={"Qual é o seu endereço?"} 
        placeholder={"Rua dos sabiás número 10"} 
        type={"text"} 
        identifier={"address"}
        setter={setAddress}
      />
      </div>
      <div className="col-span-2 ml-7 mr-7 md:col-span-4">
      <InputField 
        title={"Pra quando precisa?"} 
        placeholder={"escolha uma data"} 
        type={"date"} 
        identifier={"date"}
        setter={setDeliveryDate}
      />
      </div>
      <div className="col-span-2 ml-7 mr-7 md:col-span-4">
      <SelectField 
        title={"Por quanto tempo?"} 
        identifier={"timeRent"} 
        options={rentingOptions}
        setter={setTimeRent}
        type={"text"}
      />
      </div>
      <div className="col-span-2 ml-7 mr-7 md:col-span-4">
      <InputField 
        title={"Quantidade de caçambas"} 
        placeholder={"digite um número"}
        min={1}
        max={5}
        type={"number"} 
        identifier={"quantity"}
        setter={setQuantity}
      />
      </div>
      <div className="col-span-2 ml-7 mr-7 md:col-span-4">
      <SelectField 
        title={"Forma de pagamento"} 
        identifier={"payment"} 
        options={options}
        setter={setPayment}
        type={"text"}
      />
      </div>
      <button 
        className="px-6 py-4 mr-7 ml-7 mb-7 sm:px-4 sm:py-2 button-primary rounded-md inter-font font-semibold col-span-2 md:col-span-4 md:col-start-9"
        type="submit"
        onClick={() => sendGAEvent({ event: 'conversion', value: "Pedido de orçamento", gaID: "AW-1009573396"})}
        >
        Pedir orçamento
        <svg 
        className="w-4 h-4 text-title-brown inline-flex " 
        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" 
        fill="none" 
        viewBox="0 0 24 24">
          <path 
            stroke="currentColor" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth="2" 
            d="m9 5 7 7-7 7"/>
        </svg>
      </button>
  </form>
  )
}
