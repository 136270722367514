export default function SubTitle({text}) {
  return (
      <span 
      className="font-display font-semibold 
      tracking-tighter lg:text-4xl
      md:text-3xl sm:text-2xl text-2xl text-title-brown block">
      {text}
      </span>
  )
}
