export default function SelectField({title, identifier, options = [], setter}) {
  return (
    <div className="mb-4">
      <label 
        htmlFor={identifier} 
        className="inter-font block mb-2 text-title-brown dark:text-white font-normal">{title}
      </label>
      <select 
        id={identifier} 
        className=" bg-title-white border border-gray-300 text-title-brown text-sm rounded block w-full p-2.5 light-gray-border appearance-none" 
        required
        defaultValue={"selecione"}
        onChange={(e) => setter(e.target.value)}
      >
        <option className="inter-font" key="selecione" value="selecione" disabled>
          Selecione uma opção
        </option>
        {options.map((option) => (
          <option className="inter-font" key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}
