'use client';

import OrderForm from './ui/order-form';
import Banner from './ui/banner';
import Nav from './ui/nav';
import PhotoGrid from './ui/photo-grid';
import Card from './ui/card';
import Title from './ui/title';
import Atendimento from './ui/atendimento';
import Contato from './ui/contato';

function Home() {
  function handleClick() {
    const el = document.getElementById('mobileMenu');
    el.classList.add('hidden');
  }

  return (
    <main className="">
      <div className="grid sm:grid-cols-6 md:grid-cols-6 lg:grid-cols-12 xl:grid-cols-12">
        <div className="col-span-12">
          <div className="col-span-12 lg:mx-28 md:mx-18  ml-7 mr-7">
            <Nav
              navItem={['como funciona', 'galeria', 'atendimento', 'contato']}
              logoSrc={'../public/mra-small-icon.svg'}
            />
          </div>
        </div>
        <div className="col-span-12" onClick={handleClick}>
          <Banner />
        </div>
        <div
          className="col-span-12 lg:mx-28 md:mx-18 mt-[-4rem] sm:mt-[-4rem] md:mt-[-7rem] lg:mt-[-9rem] xl:mt-[-12rem] ml-7 mr-7 mb-7"
          id="fazerPedido"
          onClick={handleClick}
        >
          <OrderForm />
        </div>
      </div>
      <section id='como funciona'>
        <Title topText={'rápido e fácil'} text={'Descarte de entulhos'} />
        <div className="col-span-12 lg:mx-28 md:mx-18">
          <Card />
        </div>
      </section>
      <section>
        <PhotoGrid />
      </section>
      <section>
        <Atendimento />
      </section>
      <section>
        <Contato/>
      </section>
    </main>
  );
}

export default Home;
