'use client'

import Link from "next/link";
import Button from "./button";

export default function Nav({navItem = []}) {

  function handleClick() {
    const el = document.getElementById("mobileMenu")
    el.classList.contains("hidden") ? el.classList.remove("hidden") : el.classList.add("hidden")
  }

  function handleOnBlur() {
    const el = document.getElementById("mobileMenu")
    return el.classList.add("hidden")
  }

  return(
    <nav 
      className="relative"
      id='navbar'
      >
      <ul className="grid grid-cols-12">
      <li 
      className="col-span-2"
      key={"logo"}
      >
        <img 
        width={107} 
        height={61} 
        src={"/mra-small-icon.svg"} 
        className="py-2"
        alt="logomarca 'MRA'"/>
      </li>
      {navItem.map((navItem) => (
        <li 
          className="inter-font col-span-2 text-center self-center hidden md:block"
          key={navItem}
        >
          <Link 
            href={`#${navItem}`}>
            {navItem}
          </Link>
        </li>
      ))
      }
      <li className="text-center self-center hidden md:block col-span-2">
        <div 
          key={"pecajaButton"}
          >
          <Button 
            href={"#fazerPedido"} 
            buttonText={"Peça já"} 
            target=""/>
        </div>
      </li>
       <li className="col-start-12 self-center col-span-1">
        <button
          onClick={handleClick}
          type="button" 
          className="inline-flex items-center w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" 
          >
            <span className="sr-only">Abrir menu principal</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
            </svg>
          </button>
       </li>
      </ul>
      <div 
       className="col-span-6 col-start-6 rounded-md justify-center py-2 bg-pure-white absolute top-14 left-0 right-0 border light-gray-border hidden"
       id="mobileMenu"
       >
        <ul>
            {navItem.map((navItem) => (
            <li 
              className="inter-font text-center p-1 m-1 mx-2 rounded-md block  hover:text-dark-yellow hover:font-semibold"
              key={navItem}
            >
              <Link
                onClick={handleOnBlur} 
                href={`#${navItem}`}>
                {navItem}
              </Link>
            </li>
          ))
          }
        </ul>
       </div>
    </nav>
  )
}
