import Title from "./title";

export default function Atendimento() {
  return (
    <section className="my-6" id="atendimento">
      <Title text={"Atendimento"}/>
      <div className="grid col-span-12 md:grid-cols-6 lg:p-0 lg:grid-cols-12 lg:px-[7rem] md:px-5 px-4">
        <div className="bg-pure-white col-span-8 rounded-t-md mt-6 md:rounded-l-md lg:rounded-r-none">
          <p className="hidden font-body pt-8 px-8 text-xl md:block font-semibold">
            Atendemos em Osasco e São Paulo
          </p>
            <div className="grid grid-cols-3 lg:grid-cols-12 m-4 md:m-8 bg-title-white rounded-md p-4 md:p-8 font-body text-sm md:text-base text-start justify-items-center">
              <ul className="lg:col-span-2 lg:pr-8 col-span-1">
                <li>
                  Lapa
                </li>
                <li>
                  Leopoldina
                </li>
                <li>
                  Vila Madalena
                </li>
                <li>
                  Pirituba
                </li>
              </ul>
              <img 
                src="/vertical-divisor.svg" 
                aria-hidden="true"
              />
              <ul className="lg:col-span-2 col-span-1">
                <li>
                  Piqueri
                </li>
                <li>
                  Jaguaré
                </li>
                <li>
                  Vila Jaguara
                </li>
                <li>
                  Vila São José &nbsp;&nbsp;
                </li>
              </ul>
              <img 
                src="/vertical-divisor.svg" 
                aria-hidden="true"
                className="hidden lg:block"
              />
              <ul className="lg:col-span-2 col-span-1">
                <li>
                  Jardim Marisa
                </li>
                <li>
                  Remédios
                </li>
                <li>
                  Vila Mangalot
                </li>
                <li>
                  Vila Ayrosa
                </li>
              </ul>
              <img 
                src="/vertical-divisor.svg" 
                aria-hidden="true"
              />
              <ul className="lg:col-span-2 col-span-1">
                <li>
                  Vila Piauí
                </li>
                <li>
                  Osasco Centro
                </li>
              </ul>
              <p className="lg:hidden col-span-3 mt-8 text-center pb-2 text-title-gray text-xs md:text-base">entre em contato para demais localidades</p>
            </div>
            <p className="hidden lg:block col-span-2 md:col-span-12 text-center pb-4 md:pb-8 text-title-gray text-xs md:text-base">entre em contato para demais localidades</p>
        </div>
          <div className="col-span-8 bg-pure-white lg:col-span-4 lg:block lg:mt-6 rounded-b-md md:rounded-r-md lg:rounded-l-none">
            <iframe
              title="mapa da localização da empresa MRA Locação de Caçambas e Remoção de Entulhos"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.3687658339495!2d-46.761688400000004!3d-23.5192355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ceff26c7c94ae5%3A0x82bf853d07260f89!2sR.%20Sabi%C3%A1%2C%2051%20-%20Vila%20dos%20Remedios%2C%20Osasco%20-%20SP%2C%2006293-040!5e0!3m2!1spt-BR!2sbr!4v1707862372670!5m2!1spt-BR!2sbr" 
              allowFullScreen="" 
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="h-full w-full md:px-8 md:pb-8 lg:px-0 lg:pb-0 lg:rounded-none"
            />
          </div>
        </div>
    </section>
  )
}
