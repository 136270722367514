import Title from './title';

export default function Card() {
  return (
    <div>
      <span className="grid grid-cols-3 col-span-3 items-center md:col-span-3 lg:col-span-3 xl:col-span-3 2xl:col-span-3 mt-6 lg:justify-items-center">
        <span className="col-span-3 border border-border-light-gray rounded-md h-44 flex items-center sm:ml-7 mr-4 ml-4 sm:mr-7 mb-6 p-2 sm:pt-4 sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1 2xl:col-span-1 bg-pure-white sm:flex-col md:flex-col lg:flex-col xl:flex-col 2xl:flex-col sm:h-80 md:h-80 lg:h-80 xl:h-80 2xl:h-80 xl:w-[17.5rem] sm:max-w-[17.5rem] ">
          <div className="flex-none rounded-full bg-light-gray h-36 w-36 flex justify-center items-center">
            <div className="rounded-full bg-dark-gray h-24 w-24 flex justify-center text-center items-center">
              <p className="font-body text-6xl font-bold text-pure-white">1</p>
            </div>
          </div>
          <span className="py-2.5 px-3 lg:px-8 mt-2">
            <h3 className="font-bold font-display md:text-3xl text-2xl text-title-brown mb-1">
              Contato
            </h3>
            <p className="text-base md:text-lg text-title-brown">
              entre em contato e faça seu pedido
            </p>
          </span>
        </span>
        <span className="col-span-3 border border-border-light-yellow rounded-md h-44 flex items-center sm:ml-7 mr-4 ml-4 sm:mr-7 mb-6 p-2 sm:pt-4 sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1 2xl:col-span-1 bg-light-yellow sm:flex-col md:flex-col lg:flex-col xl:flex-col 2xl:flex-col sm:h-80 md:h-80 lg:h-80 xl:w-[17.5rem] xl:h-80 2xl:h-80 sm:max-w-[17.5rem]">
          <div className="flex-none rounded-full bg-light-brown h-36 w-36 flex justify-center items-center">
            <div className="rounded-full bg-dark-brown h-24 w-24 flex justify-center text-center items-center">
              <p className="font-body text-6xl font-bold text-light-yellow">
                2
              </p>
            </div>
          </div>
          <span className="py-2.5 px-3 lg:px-8 mt-2">
            <h3 className="font-bold font-display md:text-3xl text-2xl text-title-brown mb-1">
              Entrega
            </h3>
            <p className="text-base md:text-lg text-title-brown">
              fazemos a entrega no dia que você escolheu
            </p>
          </span>
        </span>
        <span className="col-span-3 border border-border-green rounded-md h-44 flex items-center sm:ml-7 mr-4 ml-4 sm:7 mb-6 p-2 sm:pt-4 sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1 2xl:col-span-1 bg-dark-green sm:flex-col md:flex-col lg:flex-col xl:flex-col 2xl:flex-col sm:h-80 md:h-80 lg:h-80 xl:h-80 2xl:h-80 xl:w-[17.5rem] sm:max-w-[17.5rem]">
          <div className="flex-none rounded-full bg-middle-green h-36 w-36 flex justify-center items-center">
            <div className="rounded-full bg-light-green h-24 w-24 flex justify-center text-center items-center">
              <p className="font-body text-6xl font-bold text-dark-green">3</p>
            </div>
          </div>
          <span className="py-2.5 px-3 lg:px-8 mt-2">
            <h3 className="font-bold font-display lg:text-3xl text-2xl text-pure-white mb-1">
              Retirada
            </h3>
            <p className="text-base md:text-lg text-title-white">
              retiramos pra você na data agendada
            </p>
          </span>
        </span>
      </span>
    </div>
  );
}
