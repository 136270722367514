export default function Title({ text, topText }) {
  return (
    <span className="grid md:grid-cols-6 p-1 lg:p-0 lg:grid-cols-12 lg:px-[7rem] md:px-5 px-4" >
      <span className="col-span-12 pl-1 lg:pl-2 sm:text-sm md:text-lg text-title-gray">
        {topText}
      </span>
      <span 
      className="md:col-span-12 font-display font-semibold 
      tracking-tighter lg:text-7xl 
      md:text-5xl text-4xl text-title-brown">
      {text}
      </span>
    </span>
  );
}
