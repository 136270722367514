export default function InputField({title, placeholder, type, pattern, identifier, setter, min, max}) {
  return (   
    <div className="mb-4">
      <label 
        htmlFor={identifier} 
        className="block mb-2 text-title-brown dark:text-white inter-font font-normal">
        {title}
      </label>
      <input 
          onChange={(e) => setter(e.target.value)}
          type={type} 
          id={identifier} 
          className="bg-title-white border border-gray-300 text-title-brown font-light text-sm rounded block w-full p-2.5 light-gray-border inter-font" 
          placeholder={placeholder} 
          pattern={pattern}
          min={min}
          max={max}
          required
          autoComplete="on"
        />
    </div>
  )
}
