import SubTitle from './subtitle';
import Title from './title';

export default function Contato() {
  return (
    <div id="contato">
      <Title text={'Contato'} />
      <aside className="grid grid-cols-2 md:grid-cols-6 lg:grid-cols-12 lg:px-[7rem] md:px-5 px-4">
        <img
          src="/poster-contact-resized.webp"
          alt="poster vertical de cor amarela com dados sobre a empresa MRA Remoção"
          className="hidden lg:block lg:col-span-6 rounded-s-md self-center 2xl:w-[100%] 2xl:h-[90%]"
        />
        <div className="col-span-6 lg:col-start-7 bg-pure-white px-5 py-7 rounded-md border border-hrgray mb-4">
          <p className="font-body col-span-5 text-sm mt-2">contate-nos</p>
          <SubTitle text={'Precisa de mais informações?'} />
          <p className="font-body col-span-5 text-base mt-3">
            sinta-se à vontade para enviar um e-mail ou ligar pra gente
          </p>
          <hr className="mt-3" style={{ color: '#D6D6D6' }} />

          <div className="col-span-5 my-7 bg-title-white rounded-md text-sm md:text-base">
            <div className="flex items-center">
              <div className="flex-none rounded-full bg-middle-yellow w-12 h-12 flex items-center justify-center ml-4">
                <img
                  src="/mail.svg"
                  alt="Icone de e-mail"
                  className="w-6 h-6"
                />
              </div>
              <div className="ml-4 my-4">
                <p className="font-bold">Email</p>
                <p className="text-title-gray">mraremocao@gmail.com</p>
              </div>
            </div>
          </div>
          <div className="col-span-5 my-7 bg-title-white rounded-md text-sm md:text-base">
            <div className="flex items-center">
              <div className="flex-none rounded-full bg-middle-yellow w-12 h-12 flex items-center justify-center ml-4">
                <img
                  src="/phone.svg"
                  alt="Icone de e-mail"
                  className="w-6 h-6"
                />
              </div>
              <div className="ml-4 my-4">
                <p className="font-bold">Telefone</p>
                <p className="text-title-gray">(11)99204-8864</p>
              </div>
            </div>
          </div>
          <div className="col-span-5 my-7 bg-title-white rounded-md text-sm md:text-base">
            <div className="flex items-center">
              <div className="flex-none rounded-full bg-middle-yellow w-12 h-12 flex items-center justify-center ml-4">
                <img
                  src="/address.svg"
                  alt="Icone de e-mail"
                  className="w-6 h-6"
                />
              </div>
              <div className="ml-4 my-4">
                <p className="font-bold">Endereço</p>
                <p className="text-title-gray">
                  R. Sabiá, 51 - Vila dos Remedios,
                  <br />
                  Osasco- SP, 06293-040, Brazil
                </p>
              </div>
            </div>
          </div>
          <img
            src="/poster-contact-resized.webp"
            alt="poster vertical de cor amarela com dados sobre a empresa MRA Remoção"
            className="rounded-md lg:hidden mb-4"
          />
          <div className="grid grid-cols-2 gap-4">
            <a 
              href="https://www.facebook.com/profile.php?id=61555563602057&sfnsn=wiwspwa&mibextid=RUbZ1f"
              target='_blank'
            >
              <div className="col-span-1 bg-blue-facebook h-12 lg:h-20 rounded-md flex items-center justify-center">
                <img
                  src="/facebook-logo.svg"
                  alt="logo do Facebook na cor branca"
                />
              </div>
            </a>
            <a 
            href="https://www.instagram.com/mra_remocao?igsh=MXJnaGxkMWU0M3pmeA=="
            target='_blank'
            >
              <div className="col-span-1 button-instagram h-12 lg:h-20 rounded-md flex items-center justify-center">
                <img
                  src="/insta-logo.svg"
                  alt="logo do Instagram na cor branca"
                />
              </div>
            </a>
          </div>
        </div>
        <p className="col-span-12 flex justify-center text-title-gray my-4 text-xs lg:text-base">
        &copy; 2024 MRA Remoção |&nbsp;<a className=" underline" href="mailto:nickspoot@gmail.com">BestCo
          </a>
        </p>
      </aside>
    </div>
  );
}
