import Title from "./title"

export default function PhotoGrid() {
return (
    <div className="mb-3" id="galeria">
        <Title text={"Galeria"}/>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 lg:px-[7rem] md:px-5 px-4 pt-6">
            <div className="grid gap-4">
                <div>
                    <img 
                      className="h-auto w-auto rounded-md"
                      src="/mra01-resized.webp" 
                      alt=""/>
                </div>
                <div className="hidden md:block">
                    <img className="h-auto w-auto rounded-md" src="/mra05-resized.webp" alt=""/>
                </div>
            </div>
            <div className="grid gap-4">
                <div>
                    <img 
                      className="h-auto w-auto rounded-md" 
                      src="/mra02-resized.webp"
                      alt=""/>
                </div>
                <div className="hidden md:block">
                    <img className="h-auto w-auto rounded-md" src="/mra06-resized.webp" alt=""/>
                </div>
            </div>
            <div className="grid gap-4">
                <div className="hidden md:block">
                    <img className="h-auto w-auto rounded-md" src="/mra03-resized.webp" alt=""/>
                </div>
                <div>
                    <img className="h-auto w-auto rounded-md" src="/mra07-resized.webp" alt=""/>
                </div>
            </div>
            <div className="grid gap-4">
                <div>
                    <img className="h-auto w-auto rounded-md" src="/mra04-resized.webp" alt=""/>
                </div>
                <div className="hidden md:block">
                    <img className="h-auto w-auto rounded-md" src="/mra08-resized.webp" alt=""/>
                </div>
            </div>
        </div>
    </div>
  )
}
