import Image from 'next/image';
import BannerImage from "../../public/banner-resized.webp";


export default function Banner() {
  return (
    <Image
      src={BannerImage}
      alt="Parede de prédio em construção com a cor cinza, onde há quatro banners, um deles em destaque por ser o maior, e de uma cor diferente: ele é retangular verde escuro, contendo em branco um número de telefone, o título 'Precisando de caçambas?' e o URL 'www.mraremocao.com.br', nesse mesmo retângulo também se encontra a logo do MRA remoção, envolta em dois círculos verdes claro. Sobre a logo, se trata de uma caçamba amarela com as iniciais 'MRA' em sua lateral, talbém em forma de cículo se encontram duas frases em verde escuro: 'remoção de entulhos' e 'aluguel de caçambas'. Sobre os três banners menores, eles são de cor branca e todos contém a URL do site 'www.mraremocao.com.br'"
      priority
    />
  );
}
