import Link from "next/link";

export default function Button({href, buttonText, target = '_blank', onClick}) {
  return (
    <Link
    href={href}
    target={target} 
    >
      <div className="px-6 py-4 sm:px-4 sm:py-2 button-primary rounded-md inter-font font-semibold">
        {buttonText}
        <svg className="w-6 h-6 text-title-brown inline-flex" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m9 5 7 7-7 7"/>
          </svg>
      </div>
    </Link>
  );
}
